// common js

var windowWidth = screen.availWidth;
var windowSm = 768;

if (windowWidth > windowSm) {

} else {

}



common();




function common(){
  mainvisual();
  smoothScroll();
  sliderBox();
  sliderPhoto();
  horizonScroll();
  spheader();
  spmenu();
  spbar();
  // accordion();
  accordionChild();
  newsAccordion();
  serchScroll();
}



function mainvisual(){
  var target = $('.o-mv__images');
  target.slick({
    'autoplay': true,
    'arrows': false,
    'speed': 1000,
    'autoplaySpeed': 4000,
  });
}


function smoothScroll(){
  $(document).on('click', 'a[href*="#"]' ,function(){
    var href = $(this).attr('href');
    href = href.split('#')[1];
    var target = $('#'+href);
    var headspace = 0;
    if (target.length) {
      var position = target.offset().top - headspace;
      $("html, body").animate({scrollTop:position}, 700, "swing");
      return false;
    }
  });
}


function sliderBox(){
  var target = $('.c-slider-box');
  target.each(function(){
    var $this = $(this);
    var content = $this.find('.c-slider-box__content');
    var bar = $this.find('.c-slider-box__bar');
    var line = $this.find('.c-slider-box__bar__line span');
    var start = $this.find('.c-slider-box__bar__start');
    var end = $this.find('.c-slider-box__bar__end');
    var numParent = content.children().length;
    if ($this.hasClass('-blue')) {
      var numParent = content.children().length / 2;
    }

    start.text('01');
    end.text(zeroPadding(numParent, 2));

    var slidesToShow = 5;
    content.slick({
      'slidesToShow': slidesToShow,
      'speed': 600,
      'infinite': true,
      'centerMode': true,
      'swipeToSlide': true,
      'prevArrow': '<div class="c-slider-box__arrow -prev"></div>',
      'nextArrow': '<div class="c-slider-box__arrow -next"></div>',
      responsive: [
        {
         breakpoint: windowSm,
         settings: {
           slidesToShow: 1,
         }
        }
      ]
    });

    line.css({
      'width': 1 / (numParent)*100+'%',
    });
    content.on('beforeChange', function(event, slick, currentSlide, nextSlide){
      // console.log(numParent);
      // console.log(nextSlide);
      var barWidth = nextSlide % numParent / (numParent);
      line.css({
        'left': barWidth*100+'%',
      });
    });
  });
}

function sliderPhoto(){
  var target = $('.c-slider-photo');
  target.each(function(){
    var $this = $(this);
    var main = $this.find('.c-slider-photo__main');
    var sub = $this.find('.c-slider-photo__sub');
    var content = main.find('ul');
    var images = main.find('li img');
    var array = [];

    images.each(function(){
      array.push($(this).attr('src'));
    });

    content.slick({
      'slidesToShow': 1,
      'speed': 400,
      'swipeToSlide': true,
      'prevArrow': '<div class="c-slider-photo__arrow -prev"></div>',
      'nextArrow': '<div class="c-slider-photo__arrow -next"></div>',
      'dots': true,
      'appendDots': sub,
    });

    for (var i = 0; i < array.length; i++) {
      sub.find('li').eq(i).html('<img src="'+array[i]+'">');
    }
  });

  
  target.find('.c-slider-photo__main li').on('click', function(){
    var img = $(this).find('img');
    if(!img.length) return;

    var imgURL = img.attr('src');

    var zoomHtml = '\
      <div class="c-modal-zoom">\
        <div class="c-modal-zoom__bg"></div>\
        <div class="c-modal-zoom__close"></div>\
        <figure class="c-modal-zoom__img"><img src="'+imgURL+'" alt=""></figure>\
      </div>\
    ';
    $('.c-modal-zoom').remove();
    $('body').append(zoomHtml);
    $('.c-modal-zoom').fadeIn();
  });
  $(document).on('click', '.c-modal-zoom__bg, .c-modal-zoom__close', function(){
    $('.c-modal-zoom').fadeOut();
  });
}



function horizonScroll(){
  var target = $('.horizonScroll');
  window.addEventListener('scroll', _handleScroll, false);

  function _handleScroll() {
    target.css({
      'left': -window.scrollX + 'px',
    });
  }
}



function spheader(){
  var target = $('.l-header');
  var scroll;
  var offsetTop = target.offset().top;
  $(window).on('resize, scroll', function(){
    if (offsetTop == 0 && $('#space').length) {
      offsetTop = $('#space').offset().top;
    }
    var space = '<div id="space" style="height: '+target.height()+'px">';
    var windowWidth = screen.availWidth;
    if (windowWidth > windowSm) {
      $('#space').remove();
    } else {
      scroll = $(window).scrollTop();
      if (!$('#space').length) {
        target.after(space);
      }
      if (scroll >= offsetTop) {
        target.addClass('-follow');
      }else{
        target.removeClass('-follow');
        $('#space').remove();
      }
    }
  });
}



function spmenu(){
  var target = $('.l-header');
  var btn = $('.c-btn-menu');
  var nav = $('.o-gnav');
  var scroll;
  btn.on('click', function(){
    if (target.hasClass('-menu')) {
      nav.fadeOut();
      target.removeClass('-menu');
      $('body').removeClass('-fixed');
      $(window).scrollTop(scroll);
      $('body').css({
        'top': '',
      });
    }else{
      scroll = $(window).scrollTop();
      nav.fadeIn();
      target.addClass('-menu');
      $('body').addClass('-fixed');
      $('body').css({
        'top': scroll*-1+'px',
      });
    }
  });
}



function spbar(){
  var scroll_val = 0;
  var pre_pos = 0;
  var pre_status;
  var bar = $('.o-bar-contact');
  $(window).scroll(function(){
    scroll_val = $(window).scrollTop();
    if (scroll_val > 0) {
      if (windowWidth <= windowSm) {
        var now_pos = $(window).scrollTop();
        if(pre_pos == now_pos){
          if(pre_status == true){
            _upward();
          }else{
            _downward();
          }
        }else if(pre_pos > now_pos){
          _upward();
          pre_status = true;
        }else{
          _downward();
          pre_status = false;
        }
        pre_pos = now_pos;
      }
    }
  });
  function _upward(){
    bar.removeClass('-sphidden');
  }
  function _downward(){
    bar.addClass('-sphidden');
  }
}



// function accordion(){
//   var items = $('.c-acdn');
//   items.each(function(){
//     var item = $(this);
//     var item_p = item.find('.c-acdn__parent');
//     var item_c = item.find('.c-acdn__children');
//     $(document).ready(function(){
//       if (item.hasClass('-active')) {
//         item_c.slideDown();
//       }
//     });
//     item_p.click(function(){
//       if (item.hasClass('-active')) {
//         item_c.slideUp();
//         item.removeClass('-active');
//       }else{
//         item_c.slideDown();
//         item.addClass('-active');
//       }
//     });
//   });
// }

function accordionChild(){
  var item = $('.c-acdn__grandchildren')
  // $(document).ready(function(){
  //   if (item.prev().hasClass('-active')) {
  //     $('.-active').next(item).slideDown();
  //   }
  // });
  item.prev().click(function() {
    if ($(this).hasClass('-active')) {
      $(this).next().slideUp();
      setTimeout(() => {
        $(this).removeClass('-active');
      }, 400)
    }else{
      $(this).next().stop().slideDown();
      setTimeout(() => {
        $(this).addClass('-active');
      }, 400)
    }
  });
}


function newsAccordion() {
  if(location.hash) {
    _accordion($(location.hash).find('.o-archive-news__item__head'));
  }

  const btns = $('.o-archive-news__item__head');
  btns.click(function() {
    _accordion($(this));
  });

  function _accordion(target) {
    target.siblings('.o-archive-news__desc').slideToggle();
    target.parents('.o-archive-news__item').toggleClass('-active');
  }
}




function zeroPadding(NUM, LEN){
  return ( Array(LEN).join('0') + NUM ).slice( -LEN );
}


function serchScroll(){
  const navSide = document.querySelector('.o-nav-side');

  if(navSide){
    let url = new URL(window.location.href);
    let params = url.searchParams;
    let getParam = params.get('nav');
    if(getParam){
      navSide.scrollTop = getParam;
    }

    const navSideLinks = document.querySelectorAll('.o-nav-side a');

    navSideLinks.forEach((navSideLink) => {
      navSideLink.addEventListener('click', (e) => {
        let href = navSideLink.href;
        href += '&nav=' + navSide.scrollTop;
        navSideLink.href = href;
      }, false);
    });
  }
}